const Tag = () => {
	return (
		<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16.4915 4.05613C16.3833 3.94758 16.2548 3.86149 16.1132 3.80283C15.9717 3.74416 15.8199 3.71407 15.6667 3.7143H5.16667C4.85725 3.7143 4.5605 3.83721 4.34171 4.056C4.12292 4.2748 4 4.57154 4 4.88096V15.381C4 15.6913 4.1225 15.9876 4.34183 16.2058L13.6752 25.5391C13.7833 25.6478 13.9118 25.7341 14.0533 25.793C14.1949 25.8519 14.3467 25.8822 14.5 25.8822C14.6533 25.8822 14.8051 25.8519 14.9467 25.793C15.0882 25.7341 15.2167 25.6478 15.3248 25.5391L25.8248 15.0391C25.9333 14.9309 26.0193 14.8023 26.078 14.6608C26.1367 14.5192 26.1669 14.3675 26.1669 14.2143C26.1669 14.0611 26.1367 13.9094 26.078 13.7678C26.0193 13.6263 25.9333 13.4977 25.8248 13.3895L16.4915 4.05613ZM14.5 23.0646L6.33333 14.898V6.04763H15.1837L23.3503 14.2143L14.5 23.0646Z"
				fill="url(#paint0_linear_197_3496)"
			/>
			<path
				d="M10.412 11.881C11.3817 11.881 12.1679 11.0949 12.1679 10.1252C12.1679 9.15544 11.3817 8.36932 10.412 8.36932C9.4423 8.36932 8.65619 9.15544 8.65619 10.1252C8.65619 11.0949 9.4423 11.881 10.412 11.881Z"
				fill="url(#paint1_linear_197_3496)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_197_3496"
					x1="4.31667"
					y1="4.86887"
					x2="16.1709"
					y2="29.7256"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#8E2DE2" />
					<stop offset="1" stopColor="#4A00E0" />
					<stop offset="1" stopColor="#4A00E0" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_197_3496"
					x1="8.70636"
					y1="8.55222"
					x2="10.5842"
					y2="12.4899"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#8E2DE2" />
					<stop offset="1" stopColor="#4A00E0" />
					<stop offset="1" stopColor="#4A00E0" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default Tag;
