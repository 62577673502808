const Earth = () => {
	return (
		<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.5 2.71429C6.986 2.71429 2.5 7.20029 2.5 12.7143C2.5 18.2283 6.986 22.7143 12.5 22.7143C18.014 22.7143 22.5 18.2283 22.5 12.7143C22.5 7.20029 18.014 2.71429 12.5 2.71429ZM4.5 12.7143C4.5 11.8153 4.656 10.9523 4.931 10.1453L6.5 11.7143L8.5 13.7143V15.7143L10.5 17.7143L11.5 18.7143V20.6453C7.561 20.1503 4.5 16.7863 4.5 12.7143ZM18.83 17.5873C18.177 17.0613 17.187 16.7143 16.5 16.7143V15.7143C16.5 15.1839 16.2893 14.6752 15.9142 14.3001C15.5391 13.925 15.0304 13.7143 14.5 13.7143H10.5V10.7143C11.0304 10.7143 11.5391 10.5036 11.9142 10.1285C12.2893 9.75343 12.5 9.24473 12.5 8.71429V7.71429H13.5C14.0304 7.71429 14.5391 7.50358 14.9142 7.12851C15.2893 6.75344 15.5 6.24473 15.5 5.71429V5.30329C18.428 6.49229 20.5 9.36429 20.5 12.7143C20.4998 14.479 19.9123 16.1934 18.83 17.5873V17.5873Z"
				fill="url(#paint0_linear_197_3501)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_197_3501"
					x1="2.78571"
					y1="3.75596"
					x2="13.4804"
					y2="26.1822"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#8E2DE2" />
					<stop offset="1" stopColor="#4A00E0" />
					<stop offset="1" stopColor="#4A00E0" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default Earth;
