import { motion } from "framer-motion/dist/framer-motion";

const Twitter = () => {
	return (
		<motion.svg
			whileHover={{ scale: 1.2 }}
			width="25"
			height="22"
			viewBox="0 0 25 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M22.4316 5.90772C22.4481 6.13035 22.4481 6.3517 22.4481 6.57305C22.4481 13.3472 17.2921 21.153 7.86943 21.153C4.96642 21.153 2.26949 20.3121 0 18.8517C0.412172 18.8988 0.809078 18.9153 1.23779 18.9153C3.54416 18.9209 5.78522 18.1497 7.59974 16.726C6.53041 16.7066 5.49381 16.3538 4.63469 15.7168C3.77557 15.0798 3.13681 14.1905 2.8076 13.1729C3.12436 13.22 3.4424 13.2518 3.7757 13.2518C4.23494 13.2518 4.69672 13.1882 5.12543 13.0775C3.96494 12.8432 2.92142 12.2141 2.17232 11.2974C1.42322 10.3806 1.01477 9.23264 1.01644 8.04873V7.98512C1.69957 8.36549 2.49211 8.60338 3.33172 8.63518C2.62834 8.16778 2.0516 7.53354 1.65294 6.78905C1.25427 6.04455 1.04609 5.21295 1.04697 4.36844C1.04697 3.41688 1.30012 2.5442 1.7441 1.78346C3.03167 3.36729 4.63757 4.66302 6.45779 5.58671C8.278 6.5104 10.2719 7.04147 12.3105 7.14551C12.2316 6.76387 12.1832 6.36823 12.1832 5.97133C12.1829 5.29832 12.3152 4.63184 12.5726 4.01C12.83 3.38816 13.2074 2.82314 13.6833 2.34725C14.1592 1.87137 14.7242 1.49394 15.3461 1.23654C15.9679 0.979146 16.6344 0.846834 17.3074 0.847169C18.7831 0.847169 20.115 1.46543 21.0513 2.46532C22.1985 2.24349 23.2986 1.82471 24.3029 1.22754C23.9205 2.41166 23.1195 3.41574 22.0499 4.05168C23.0674 3.93563 24.0617 3.66807 25 3.25786C24.2992 4.27951 23.4308 5.17541 22.4316 5.90772Z"
				fill="white"
			/>
		</motion.svg>
	);
};

export default Twitter;
